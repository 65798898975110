import React from 'react'
import Layout from '../../components/layout'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Faq1 = () => {
  return (
    <Layout>
      <Container>
        <Row>
          <div className="back-button mb-4 mt-3">
            <Link to="/faq/">&larr; Back</Link>
          </div>
          <Col sm="12">
            <div className="mb-5">
              <StaticImage
                src="../../images/woman-smiling-glasses.jpg"
                alt="Woman smiling"
                placeholder="none"
                layout="fullWidth"
              />
              <h2 className="mt-3">
                What are Lumineers<sup>&reg;</sup>?
              </h2>
              <p>
                Lumineers<sup>&reg;</sup> are proprietary porcelain dental
                veneers that are applied individually over each original tooth
                maintaining as much of the original tooth structure as possible.
                Lumineers are digitally designed to be ultra-thin (about as thin
                as a contact lens) and crafted to mimic the translucent anatomy
                of natural teeth. Lumineers are fabricated exclusively by the
                DenMat Lab in Lompoc, California, USA, and are manufactured
                under strict guidelines by technicians that specialize in
                crafting esthetic dental veneers.
              </p>
            </div>
            <div className="mb-5 text-center">
              <Link to="../../procedure/" className="intro-button mb-5">
                Learn More
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Faq1
